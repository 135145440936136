/** 按钮色 */
/** 按钮按下去的颜色 */
/** 禁用 */
/** 禁止选中文本 */
.disable-select {
  user-select: none;
}
.BMap_cpyCtrl {
  display: none;
}
.anchorBL {
  display: none;
}
.App {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
}
.App .ant-layout-header,
.App .ant-layout-header .ant-menu.ant-menu-dark {
  background-color: #317cec;
}
.App .ant-layout-sider-light {
  border-top: 8px solid #f0f2f5;
}
.App .ant-layout-header .ant-menu.ant-menu-dark > li {
  color: #fff;
}
.App .ant-layout-header .ant-menu.ant-menu-dark .ant-menu-item-selected {
  background-color: #1460d2;
}
.App .ka-wrapper {
  height: 100%;
}
.App .ka-wrapper .ka-content {
  height: 100%;
}
.App .tx-content {
  padding: 8px;
  box-sizing: border-box;
}
.App .app-content {
  flex-grow: 1;
  height: 0;
}
iframe {
  width: 100%;
  height: 100%;
  border: none;
}
.ant-picker-cell-in-view.ant-picker-cell-range-start.ant-picker-cell-range-end .ant-picker-cell-inner {
  background: #1890ff !important;
}
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  background: #ff7a45 !important;
}
.amap-logo,
.amap-copyright {
  display: none !important;
}
.table-tree {
  width: 230px;
  overflow: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-right: 12px;
}
.table-tree .table-tree-query > span {
  border-radius: 0;
}
.table-tree .table-treecontent {
  flex: 1;
  border: 1px solid #dfdfdf;
  border-top: none;
  overflow-y: auto;
}
.map-crosshair {
  cursor: crosshair !important;
}
.en_Font_size {
  font-size: 12px !important;
}
.en_Font_size button {
  font-size: 11px !important;
}
.tx-info-item-label-en {
  white-space: nowrap;
  overflow: hidden;
  text-align: left !important;
  display: inline-block !important;
}
.ant-form-item-label-en .ant-form-item-label {
  margin-right: 7px;
  text-align: left;
}
.ant-form-item-label-en label {
  text-align: left;
  white-space: nowrap;
}
